/* Blog Section Container */
.blogs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

/* Blog Section Heading */
.blogs-box {
  font-size: 32px;
  font-weight: bold;
  color: #083857; /* Text color */
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  margin-top: 50px;
  text-align: center;
}

/* Blog Card */
.card4 {
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
  width: 100%; /* Default to 100% width */
  max-width: 300px; /* Maximum width */
  height: 300px; /* Fixed height */
  background-color: #ffffff; /* Card background */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-height: 400px;
}

.card4:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Card Image Section */
.card-image {
  position: relative;
  width: 100%; /* Make it responsive */
  height: 300px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.card-image img {
  width: 100%; /* Makes the image take up the full width of its container */
  object-fit: contain; /* Ensures the image covers the area without distortion */
  display: block; /* Removes any extra spacing caused by inline elements */
}

/* Blog Category Label */
.blog-box {
  background-color: #f8c454; /* Label background */
  color: #083857; /* Text color */
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 180px;
}

.blogContent {
  font-size: 22px; /* Adjust font size for mobile */
  font-weight: 600;
  color: #000000; /* Text color */
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
  letter-spacing: 0px;
}

/* Blog Author and Date */
.blog-author {
  margin: 10px;
  font-size: 12px; /* Adjust font size */
  color: #5e5959; /* Text color */
  font-family: "Poppins", sans-serif;
}

/* Swiper Pagination */
.swiper-pagination {
  display: block; /* Show pagination */
}
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

@media (max-width: 768px) {
  .card4 {
    width: 90%;
    height: 230px; /* Allow height to adjust based on content */
    margin-bottom: 30px;

  }

  .blogs-box {
    font-size: 24px; /* Smaller font for heading */
    margin: 0px;
  }
  .blogs {
    margin-right: 20px;
  }

  .blogContent {
    font-size: 20px; /* Smaller font size for title */
  }

  .blog-author {
    font-size: 14px; /* Smaller font size for author and date */
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
