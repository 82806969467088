.Box {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 100px grey;
  // margin: 0px 0px 200px 0px;
  scale: 0.8;
  background-color: white;
  h1 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 77px;
    letter-spacing: 0em;
    align-self: flex-start;
    text-align: left;
    color: #053859;
    margin: 20px;
  }
  .circles {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    .circleContain {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .circle {
        height: 200px;
        width: 200px;
        margin: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f6f6;
        border: 3px solid #f7c651;
        h3 {
          font-size: 55px;
        }
      }
      p {
        text-align: center;
        font-size: 20px;
      }
    }
  }
  h2 {
    margin: 40px;
    background: #e5e5e578;
    color: #053859;
    padding: 15px 30px;
    border-radius: 12px;
    font-size: 35px;
  }
  .projects {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .project {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 300px;
      height: 300px;
      border-radius: 12px;
      padding: 12px;
      box-shadow: 0px 0px 10px grey;
      &:hover {
        background-color: rgba(225, 225, 225, 0.5);
        cursor: pointer;
      }
      img {
        width: 100%;
      }
      .team {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 8px;
        .mem {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            color: #053859;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .Box {
    width: 95%;
    h1 {
      text-align: center;
      font-size: clamp(25px, 4vw, 5vw);
      line-height: clamp(35px, 5vw, 5vw);
    }
    .circles {
      flex-wrap: wrap;
      .circleContain {
        .circle {
          width: clamp(80px, 10vw, 10vw);
          height: clamp(80px, 10vw, 10vw);
          margin: 10px;
          h3 {
            font-size: clamp(20px, 3vw, 5vw);
          }
        }
        p {
          font-size: clamp(10px, 2.5vw, 3vw);
        }
      }
    }
    h2 {
      font-size: clamp(25px, 4vw, 4vw);
      text-align: center;
    }
    .projects {
      flex-wrap: wrap;
      height: auto;
      .project {
        margin: 10px;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .Box {
    box-shadow: none;
    // padding: 0%;
    // margin: 0px 0px 200px 0px;
    scale: 1;
  }
  .projects {
    .project {
      box-shadow: none;
    }
  }
}
