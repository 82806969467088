.PlaceCard{
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid grey;
    background-color: white;
    width: 350px;
    height: 450px;
    .CardHead{
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin: 10px 0px;
       .circle{
           height: 80px;
           aspect-ratio: 1/1;
           border-radius: 100%;
           margin-right: 20px;
           border: 1px solid red;
       }
       .desc{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        h1{
            margin: 0px;
            margin-bottom: 20px;
            font-size: clamp(20px,1.5vw,3vw);
        }
        p{
            width: 100%;
            display: flex;
            color: #2B3B43C4;
            margin: 0px;
            align-self: flex-start;
            font-size: clamp(15px,1vw,1.5vw);
        }
       }
   }
   h5{
    font-family: Poppins;
    font-weight: 500;
    font-size: clamp(13px,0.9vw,1.2vw);
    line-height: clamp(20px,1.3vw,1.5vw);
    letter-spacing: 0em;
    text-align: justified;
    margin: 30px 0px;
   }
 
}
