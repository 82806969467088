.First {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .GetStarted {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .illustration {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 60%;
      img {
        width: clamp(400px, 40vw, 40vw);
      }
    }
    .Content {
      width: 40%;
      h1 {
        font-family: "Poppins", sans-serif;
        font-size: clamp(30px, 2.2vw, 4vw);
        font-weight: bolder;
        line-height: clamp(30px, 3vw, 4vw);
        letter-spacing: 0em;
        text-align: left;
        color: #083857;
        position: relative;
        margin: 20px 0px;
        &::before {
          content: "";
          position: absolute;
          top: 86%;
          height: 20px;
          width: 63%;
          background-color: #f7c651;
          z-index: -1;
        }
      }
      p {
        font-family: "Poppins", sans-serif;
        font-size: clamp(12px, 1vw, 1.5vw);
        font-weight: 100;
        line-height: clamp(18px, 1.3vw, 1.5vw);
        letter-spacing: 0em;
        text-align: left;
        color: #707070;
      }
      button {
        width: clamp(100px, 10vw, 15vw);
        height: clamp(50px, 4vw, 8vw);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-size: clamp(12px, 1.2vw, 1.5vw);
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0em;
        background-color: #053859;
        border: 1px solid #053859;
        text-align: left;
        color: white;
        margin: 20px 0px;
        outline: none;
        border-radius: 5px;
        &:hover {
          background-color: white;
          color: #053859;
          cursor: pointer;
          transition: all 0.2s linear;
        }
      }
    }
  }
  .Empowering {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40vh;
    h1 {
      font-family: "Poppins", sans-serif;
      font-size: clamp(25px, 2.8vw, 5vw);
      font-weight: 700;
      line-height: 62px;
      letter-spacing: 0em;
      text-align: left;
      color: #083857;
      margin: 25px 0px;
    }
    .success {
      display: flex;
      padding: 12px;
      background-color: #f6f6f6;
      border-radius: 12px;
      height: 180px;
      .field {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        width: 12vw;
        h1 {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
          font-size: clamp(30px, 2.3vw, 5vw);
        }
        p {
          color: #083857;
          font-family: "Poppins", sans-serif;
          font-size: clamp(15px, 1.2vw, 1.5vw);
          font-weight: 600;
          width: 80%;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
      .line {
        height: 100%;
        width: 1px;
        background-color: black;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .First {
    .GetStarted {
      flex-direction: column;
      .illustration {
        width: 95%;
        margin: 12px 0px;
        img {
          width: 100%;
        }
      }
      .Content {
        width: 95%;
        height: 70%;
        h1 {
          &::before {
            width: 93%;
          }
        }
      }
    }
    .Empowering {
      h1 {
        text-align: center;
        line-height: clamp(30px, 3vw, 5vw);
      }
      height: auto;
      .success {
        flex-direction: column;
        height: auto;
        .field {
          width: 70vw;
          height: 150px;
        }
        .line {
          width: 100%;
          height: 2px;
        }
      }
    }
  }
}
