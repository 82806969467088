.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    min-height: 100vh;
    background: linear-gradient(to bottom, #ffffff, #f7f7f7);
    text-align: left;
    transition: all 0.3s ease-in-out;
}

.blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    color: #333;
    text-transform: capitalize;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    letter-spacing: 1.5px;
    transition: color 0.3s ease;
}

.blog-title:hover {
    color: #007bff;
}

.blog-title::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: #007bff;
    margin: 10px auto 0;
    border-radius: 2px;
}

.blog-content {
    font-size: 1.2rem;
    color: #444;
    white-space: pre-wrap;
    max-width: 800px;
    margin: 20px auto;
    line-height: 1.8;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.blog-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.blog-content img:hover {
    transform: scale(1.05);
}

.author-bio-section {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 30px 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.like-button {
    padding: 10px 20px;
    margin-left:  20%;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-right: auto;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

.like-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.comments-section {
    margin: 30px 0;
    max-width: 100%;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.3s ease;
}

.comment {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.comment:hover {
    transform: translateX(5px);
    background-color: #f0f0f0;
}

.comment-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.comment-input:focus {
    border-color: #007bff;
}

.add-comment-button {
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-comment-button:hover {
    background-color: #1e7e34;
    transform: scale(1.05);
}

.share-button {
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    background-color: #17a2b8;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
}

.share-button:hover {
    background-color: #117a8b;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    background: #007bff;
    transition: width 0.3s ease;
    z-index: 1000;
}

.go-back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.go-back-button:hover {
    background-color: #0056b3;
}
