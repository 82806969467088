.active {
  border-bottom: 15px solid #f7c651;
}
.Third {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .techStack {
    width: 100%;
    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-height: 10vh;
      width: 100%;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-size: clamp(15px, 1vw, 1.5vw);
        font-weight: 600;
        line-height: 62px;
        cursor: pointer;
        letter-spacing: 0em;
        text-align: left;
        list-style: none;
        color: #053859;
        // padding:0px 12px;
        margin: 12px;
        img {
          width: clamp(20px, 3vw, 4vw);
          margin-left: 2px;
        }
        &:hover {
          border-bottom: 5px solid #f7c651;
        }
      }
    }
    .CardContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        font-family: "Poppins", sans-serif;
        font-size: clamp(30px, 2.2vw, 4vw);
        font-weight: bolder;
        line-height: clamp(30px, 3vw, 4vw);
        letter-spacing: 0em;
        text-align: left;
        color: #083857;
        position: relative;
        margin: 30px 0px;
        &::before {
          content: "";
          position: absolute;
          top: 70%;
          height: 15px;
          width: 100%;
          background-color: #f7c651;
          z-index: -1;
        }
      }
      img {
        margin: 20px 0px;
      
        height: clamp(40px, 25vw, 50vw);
      }
      p {
        font-size: clamp(15px, 1.5vw, 2vw);
        color: #053859;
      }

      //old css

      // .techCard{
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     text-align: center;
      //     width: 22%;
      //     background-color: #F6F6F6;
      //     padding: 20px;
      //     margin: 100px 30px;
      //     border-radius: 12px;
      //     cursor: pointer;
      //     &:nth-child(2){
      //         scale: 1.3;
      //         background-color: white;
      //         border: 1px solid #dbdbdb;
      //         box-shadow: 0px 0px 10px #dbdbdb;
      //     }
      //     &:hover{
      //         background-color: #dbdbdb;
      //     }
      //     h1{
      //         font-family: Poppins;
      //         font-size: clamp(15px,1.5vw,1.5vw);
      //         font-weight: 700;
      //         line-height: 62px;
      //         letter-spacing: 0em;
      //         text-align: left;
      //         margin: 0px;
      //     }
      //     p{
      //         font-family: Poppins;
      //         font-size: clamp(15px,1vw,1.3vw);
      //         font-weight: 400;
      //         line-height: 31px;
      //         letter-spacing: 0em;
      //         text-align: center;
      //         margin: 20px 0px;
      //     }
      //     .line{
      //         height: 2px;
      //         width: 90%;
      //         background-color: #053859;
      //     }
      // }
    }
  }
  .celebrating {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    width: 80%;
    h1 {
      font-family: Poppins;
      font-weight: 700;
      font-size: clamp(25px, 2.8vw, 5vw);
      line-height: clamp(30px, 4vw, 4vw);
      letter-spacing: 0em;
      text-align: center;
      color: #053859;
      margin: 20px 0px;
    }
    p {
      display: flex;
      width: 100%;
      align-self: center;
      font-weight: 500;
      font-size: clamp(10px, 1.2vw, 5vw);
      text-align: center;
    }
    .imgs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      justify-content: space-around;
      img {
        height: auto;
        margin: 20px;
        width: clamp(80px, 10vw, 10vw);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Third {
    .techStack {
      .CardContainer {
        flex-wrap: wrap;
        .techCard {
          width: 70%;
          max-width: 300px;
          margin: 20px 15px;
        }
        p {
          text-align: center;
        }
        h1 {
          &::before {
            content: "";

            width: 90%;
          }
        }
        img{
              width: clamp(200px, 10vw, 20vw);
        }
      }
    }
    .celebrating {
      p {
        font-size: 15px;
      }
    }
  }
}
