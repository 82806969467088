.Home{
    overflow-x: hidden; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    &>*{
      width: 90%;
    }
}