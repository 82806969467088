.Footer {
    background-color: #053859;
    padding: 20px;
    margin-top: 100px;
    min-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100vw !important;

    .Logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        color: white;
        font-family: Poppins, sans-serif;
        font-size: clamp(40px, 4vw, 4vw) !important;
        font-weight: 700;
        line-height: clamp(55px, 3.7vw, 4vw);
        text-align: center;
    }

    img {
        padding: 10px;
        height: 100px !important;
        width: 100px !important;
    }

    .line {
        width: 90%;
        height: 2px;
        margin: 30px;
        background-color: white;
    }

    .Footer_body {
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        ul {
            color: white;
            margin: 30px 0px;
            padding: 20px;

            h2 {
                margin-bottom: 50px;
                font-size: 18px;
                font-weight: bold;
            }

            li {
                list-style: none;
                margin-top: 20px;
                cursor: pointer;
                transition: color 0.3s ease;

                #icon {
                    margin-right: 20px;
                }

                &:hover {
                    text-decoration: underline;
                    color: #f7c651;
                }
            }
        }

        .join {
            background-color: white;
            padding: 20px;
            margin: 30px 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 15px;
            width: clamp(300px, 20vw, 30vw);

            h3 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 15px;
                color: #053859;
            }

            .email {
                display: flex;
                height: 40px;
                width: 100%;
                margin: 15px 0px;

                input {
                    width: 70%;
                    outline: none;
                    border: none;
                    padding: 5px;
                    font-size: 14px;
                }

                button {
                    width: 30%;
                    background-color: #f7c651;
                    border: 1px solid #f7c651;
                    cursor: pointer;
                    font-size: 14px;
                    transition: all 0.3s ease;

                    &:hover {
                        background-color: transparent;
                        color: #f7c651;
                        border-color: #f7c651;
                    }
                }
            }

            li {
                color: black;
                opacity: 0.6;
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}
