.Fifth {
  .Student {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-family: "Poppins", sans-serif;
      font-size: clamp(25px, 2.8vw, 5vw);
      line-height: clamp(30px, 4vw, 4vw);
      font-weight: 700;
      text-align: center;
      color: #053859;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .card_contain {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 90vw;
      gap: 20px; /* Adjusted gap for consistency */

      .carousel-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin: 20px;

        img {
          margin: 10px; /* Consistent spacing between images */
          width: 600px; /* Adjust as needed */
          height: 300px;
          border-radius: 10px;
          border: 2px solid #ddd;
          padding: 10px;
          object-fit: contain;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .Pathways {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-family: Poppins, sans-serif;
      font-size: clamp(30px, 2.8vw, 5vw);
      line-height: clamp(40px, 3.5vw, 4vw);
      font-weight: 700;
      text-align: center;
      color: #053859;
      margin: 20px 0;
    }

    .Success_contain {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 40vh;
      margin: 40px 0px;

      marquee {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin: 12px;

        img {
          background-color: white;
          height: 125px;
          width: 200px;
          padding: 15px 20px;
          border-radius: 12px;
          box-shadow: 0px 0px 5px black;
          filter: grayscale(100%);
          margin: 0 15px; /* Add spacing between logos */
        }
      }
    }
  }

  .Placements {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    h1 {
      font-family: Poppins, sans-serif;
      font-size: clamp(25px, 2.8vw, 5vw);
      line-height: clamp(30px, 4vw, 4vw);
      font-weight: 700;
      letter-spacing: 0em;
      text-align: left;
      color: #053859;
      margin: 50px 0px;
    }
  }
}

/* Media query for mobile responsiveness */
@media screen and (max-width: 800px) {
  .Fifth {
    .Student {
      h1 {
        font-size: clamp(20px, 5vw, 6vw);
        margin: 10px 0;
      }

      .card_contain {
        width: 50vw;
        gap: 15px;

        .carousel-slide {
          flex-direction: column;
          padding: 10px;

          img {
            width: 350px; /* Adjust as needed */
            height: 200px;
            margin: 10px 0;
          }
        }
      }
    }

    .Pathways {
      h1 {
        font-size: clamp(18px, 4vw, 5vw);
        margin: 10px 0;
      }

      .Success_contain {
        flex-direction: column;
        gap: 10px;

        marquee {
          flex-direction: row;
          img {
            margin: 10px;
            width: 150px;
            height: 100px;
          }
        }
      }
    }

    .Placements {
      h1 {
        font-size: clamp(18px, 4vw, 5vw);
        margin: 10px 0;
      }

      .Graph {
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}

@media (max-width: 640px) {
  .animate-bounce-x {
    animation: bounceX 20s linear infinite !important;
  }
  .animate-bounce-y {
    animation: bounceY 20s linear infinite !important;
  }
}
