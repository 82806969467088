.Second{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 100px 0px;
    .AllRound{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1{
            font-family: 'Poppins', sans-serif;
            font-size: clamp(25px,2.8vw,5vw);
            font-weight: 700;
            line-height: clamp(30px,3vw,5vw);
            letter-spacing: 0em;
            text-align: center;
            color: #053859;
            width: 80%;
            margin: 30px 0px;
        }
        p{
            font-family: 'Poppins', sans-serif;
            font-size: clamp(15px,1.2vw,1.5vw);
            width: 70%;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
    .service{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 90px 0px;
        .service_box{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px solid #F7C651;
            width: clamp(250px,23vw,25vw);
            height:600px;
            border-radius: 20px;
            padding: 12px;
            margin:40px;
            &:hover{
                transition: all 0.1s linear;
                transform: scale(1.1);
                cursor: pointer;
            }
            img{
                width: clamp(100px,10vw,10vw);
                margin: 20px 0px;
            }
            h1{
                background-color: #F7C651;
                font-family: 'Poppins', sans-serif;
                font-size: clamp(18px,1.3vw,2vw);
                font-weight: 800;
                color: #053859;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                padding: 5px 20px;
                border-radius: 20px;
                margin: 20px 0px;
            }
            p{
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: clamp(12px,0.5vw,1.0vw);
                font-weight: 400;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
    .immersive{
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
            font-family: 'Poppins', sans-serif;
            font-size: clamp(25px,2.8vw,5vw);
            line-height: clamp(30px,4vw,4vw);
            font-weight: 700;
            letter-spacing: 0em;
            text-align: center;
            color: #053859;
            width: 100%;
        }
    }
}