.Students {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .Students_body {
    width: 100%; // Ensure it takes full width
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .text {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1 {
          font-family: Poppins;
          font-size: clamp(30px, 2.5vw, 4vw);
          font-weight: 700;
          line-height: clamp(40px, 3.2vw, 4vw);
          letter-spacing: 0em;
          text-align: left;
          color: #053859;
        }

        p {
          font-family: "Poppins", sans-serif;
          font-size: clamp(12px, 1vw, 1.5vw);
          font-weight: 100;
          line-height: clamp(18px, 1.3vw, 1.5vw);
          letter-spacing: 0em;
          text-align: left;
          color: #707070;
        }
        b {
          font-family: "Poppins", sans-serif;
          font-size: clamp(12px, 1vw, 1.5vw);
          font-weight: 100;
          line-height: clamp(18px, 1.3vw, 1.5vw);
          letter-spacing: 0em;
          text-align: left;
          color: #707070;
        }
        b {
          font-family: Poppins;
          font-weight: 500;
          font-size: clamp(10px, 1.5vw, 4vw);
          line-height: clamp(25px, 2vw, 3vw);
          letter-spacing: 0em;
          text-align: justified;
        }

        img {
          width: clamp(100px, 20vw, 25vw);
        }
        button {
          width: clamp(100px, 10vw, 15vw);
          height: clamp(50px, 4vw, 8vw);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Poppins;
          font-size: clamp(12px, 1.2vw, 1.5vw);
          font-weight: 500;
          line-height: 42px;
          letter-spacing: 0em;
          background-color: #053859;
          border: 1px solid #053859;
          text-align: left;
          color: white;
          margin: 20px 0px;
          outline: none;
          border-radius: 5px;
          &:hover {
            background-color: white;
            color: #053859;
            cursor: pointer;
            transition: all 0.2s linear;
          }
        }
      }

      img {
        width: clamp(300px, 35vw, 40vw);
      }
    }
  }
  @media (max-width: 768px) {
    .Students_body {
      .content {
        flex-direction: column;
        align-items: center;

        .text {
          width: 80%;
          text-align: center;

          h1 {
            text-align: center;
          }

          p {
            text-align: justify;
          }
        }

        img {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: -10px;
  // width: 105%;
  max-height: 1000vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.card-container {
  margin-top: 30px;
  display: flex;
  justify-content: center; /* Centers the cards horizontally */
  gap: 100px; /* Keeps the spacing between cards */
  margin: 0 auto; /* Centers the container within the page */
}

.card {
  margin-top: 25px;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  align-items: center; /* Centers the content horizontally */
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
  width: 300px;
}

.card-image img {
  width: 270px;
  height: auto;
  display: block;
  overflow: hidden;
  margin: 0 !important; /* Remove any margin */
  padding: 0; /* Remove any padding */
  object-position: left center;
  object-fit: cover;
}

.card-title {
  margin-top: 16px; /* Add space between the image and the title */
  font-size: clamp(
    20px,
    2vw,
    3vw
  ); /* Increase font size for better visibility */
  font-weight: bold;
  text-align: center;
  width: 100%; /* Match the width of the card */
  color: #053859; /* Optional: Adjust text color */
}

.mydiv {
  margin-top: 40px;
}

.image-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures proper spacing between the image and the form */
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  gap: 5px; /* Adds spacing between columns */
}

.invite-image {
  max-width: 50%; /* Adjusts image width to take half the container */
}

.form-container {
  width: 50%; /* Increase form width */
  max-width: 50vh; /* Adjust form width to take half the container */
  margin: 20px auto;
}

.form-group {
  padding-top: 10px;
  font-family: "Poppins";
  font-size: large;
  border-color: #000 !important;
  font-weight: bold !important;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-self: center;
}
.form-group button {
  margin-top: 50px;
  width: 200px;
  align-self: center;
}

.form-group label {
  font-weight: normal; /* Or use any value like 'normal', 'lighter', or specific weight like '700' */
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-input {
  width: 100%;
  padding: 15px;
  border: 2px solid #000000; /* Border color */
  outline: none;
  border-radius: 25px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #ffd54f, #ffd54f) border-box;
  box-shadow: 18px 18px 0 #ffd54f;
  position: relative;
}

.custom-textarea {
  width: 100%;
  padding: 20px;
  border: 2px solid #000000; /* Border color */
  outline: none;
  border-radius: 25px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #ffd54f, #ffd54f) border-box;
  box-shadow: 18px 18px #ffd54f;
  position: relative;
}

.custom-input:focus,
.custom-textarea:focus {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Stronger shadow on focus */
  border-color: #003366; /* Change border color on focus */
}

.custom-textarea {
  resize: none;
  height: 200px; /* Increase height of the textarea */
}

.submit-button {
  background-color: #003366 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #011d39 !important;
}

.trendsBlock {
  font-family: Poppins;
  font-size: clamp(30px, 2.5vw, 4vw);
  font-weight: 700;
  line-height: clamp(40px, 3.2vw, 4vw);
  margin-top: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #053859;
}

.card-container2 {
  margin-top: 30px;
  display: flex;
  justify-content: center; /* Centers the cards horizontally */
  gap: 100px; /* Keeps the spacing between cards */
  margin: 0 auto; /* Centers the container within the page */
}

.card2 {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 16px;

  width: 300px;
}

.card-image2 {
  width: 300px; /* Ensure this matches the card width */
  height: 400px;
  background-color: #ffffff;
  border: 5px solid #000;
  box-shadow: 15px 15px #053859;
  border-radius: 18px;
}

.card-title2 {
  margin-top: 16px; /* Add space between the image and the title */
  margin-bottom: 16px;
  font-size: clamp(
    20px,
    2vw,
    3vw
  ); /* Increase font size for better visibility */
  font-weight: bold;
  text-align: center;
  width: 100%; /* Match the width of the card */
  color: #053859; /* Optional: Adjust text color */
}

.card-container {
  margin-top: 30px;
  display: flex;
  justify-content: center; /* Centers the cards horizontally */
  gap: 100px; /* Keeps the spacing between cards */
  margin: 0 auto; /* Centers the container within the page */
}

.card-title {
  margin-top: 16px; /* Add space between the image and the title */
  margin-bottom: 20px;
  font-size: clamp(
    20px,
    2vw,
    3vw
  ); /* Increase font size for better visibility */
  font-weight: bold;
  text-align: center;
  width: 100%; /* Match the width of the card */
  color: #053859; /* Optional: Adjust text color */
}

.card-slider {
  margin-top: 30px;

  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 30px;
    height: 30px;
    &:before {
      font-size: 30px;
      color: #053859;
    }
  }

  .slick-dots li button:before {
    color: #053859; /* Dots color */
  }
}

.roadmap-title {
  margin-top: 30px;
  font-family: "poppins";
  color: #053859;
}

.blogs {
  padding: 20px;
  color: #053859;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.authorName {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
  text-align: center;
}

.blogContent {
  font-size: 18px;
  color: #020000;
  text-align: justify;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 8px;
  padding-bottom: 10px;
}

.blogout {
  background-color: #ffd54f;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 15px;
  background-image: linear-gradient(
    to right,
    rgb(2, 10, 69),
    rgb(172, 135, 218)
  );
}

.blur-background {
  filter: blur(5px); /* Adjust the blur intensity */
  transition: filter 0.3s ease; /* Smooth transition */
  pointer-events: none; /* Prevent interactions with blurred content */
}

.modal-content {
  z-index: 1001; /* Ensure modal is above the blur effect */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  z-index: 1000; /* Ensure modal overlay is above content */
  display: flex;
  align-items: center;
  justify-content: center;
}


.modal {
  position: fixed;
  // top: 10px;
  left: 0;
  width: 100%;
  // height: 100%;
  // margin-top: 10px;
  display: flex;
  justify-content: center;
  overflow: scroll; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox: Hides the scrollbar */
  -ms-overflow-style: none; /* For Internet Explorer and Edge: Hides the scrollbar */
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Background overlay */
}

.modal-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera: Hides the scrollbar */
}

.modal-content {
  max-height: 1400px; /* Prevents modal from exceeding screen height */
  height: 96.5%;
  width: 90%;
  max-width: 1200px; /* Adjust width as needed */
  background: #fff;
  border-radius: 10px;
  overflow-y: hidden; /* Enables vertical scrolling if content overflows */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.close {
  color: #333;
  float: right;
  font-size: 33px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}

.close:hover {
  color: #000;
}

.cover {
  overflow: hidden;
}
.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  max-height: calc(500vh - 0px); /* Adjust height to fit within modal */
}

.feedback-form textarea {
  resize: vertical;
  max-height: 150px; /* Reduce max height for better fit */
}


.feedback-form input,
.feedback-form textarea,
.feedback-form button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.feedback-form button {
  background-color: #053859;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.feedback-form button:hover {
  background-color: #03263d;
}

.read-more-link {
  color: #007bff; /* Blue color for the link */
  text-decoration: none; /* Removes underline */
  font-weight: bold; /* Makes text slightly bold */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

.read-more-link:hover {
  text-decoration: underline; /* Adds underline on hover for emphasis */
}

@media (max-width: 768px) {
  .invite-image {
    width: 100vh;
    height: 200px;
  }

  .modal-content {
    max-height: 100vh;
    max-width: 100vh;
    width: 100%;
    overflow-y: scroll;
  }

  .image-form-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensures proper spacing between the image and the form */
  }
  .form-container {
  width: 90%; /* Increase form width */
}
}
