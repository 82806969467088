.Achievements{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:url("../../Assets/achievebg.png");
    background-position: center;
    background-repeat:no-repeat;
    background-size: cover;
    width: 100vw;
    .Head_wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 100%;
        .Header{
            width: 90%;
            margin: 0px;
        }
    }
    .Achieve{
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 20px 200px;
       .image{
        position: relative;
        height: 750px;
            img{
                width: clamp(250px,50vw,70vw);
            }
            .AchieveContent{
                position: absolute;
                width: clamp(150px,30vw,40vw);
                aspect-ratio: 1/1;
                border-radius: 100%;
                background-color:#F7C651;
                top: 30%;
                left: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1{
                    color: white;
                    text-decoration: underline;
                    margin: 20px 0px;
                    font-size: clamp(12px,2.5vw,3vw);
                }
                p{
                    color: #083857;
                    text-align: center;
                    font-family: Poppins;
                    font-size: clamp(5px,1vw,2vw);
                    width: 70%;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                h2{
                    font-size: clamp(8px,2.5vw,3vw);
                    padding: 12px 30px;
                    border-radius: 50px;
                    margin: 40px 0px;
                    background-color: #FFF;
                    color: #053859;
                    cursor: pointer;
                    &:hover{
                        background-color: #053859;
                        color: white;
                        transition: all 0.2s linear;
                    }
                }
            }
       }
    }
}

@media screen and (max-width:1100px){
    .Achievements{
        width: 95vw;
        .Achieve{
            height: 70vh;
            align-items: center;
            justify-content: center;
            .image{
                height: auto;
                img{
                    width: clamp(400px,70vw,80vw);
                }
                .AchieveContent{
                    left: 50%;
                    top: 60%;
                    h1{
                        margin: 5px 0px;
                    }
                    h2{
                        margin: 10px 0px;
                        padding: 8px 15px;
                    }
                }
            }
        }
    }
}