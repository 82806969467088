/* General Styles */
.blogs {
  margin: 20px;
}

.card-swiper {
  width: 100%;
  padding: 20px 0;
}

.card3 {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px auto;
  width: 300px;
  height: 400px;
  cursor: pointer;
}

.card3:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Card Image with Background Color */
.card-image3 {
  height: 150px;
  background: #1064c4; /* Set the background color */
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Logo and Username on Top of Background */
.card-header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 2;
}

.linkedin-logo img {
  width: 50px;
  height: 50px;
  margin-left: auto; /* Center the logo */
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: auto;
}

/* Training Feedback */
.training-feedback {
  padding: 10px;
  font-size: 16px;
  color: #000000;
  line-height: 1.5;
  background-color: #ffffff;
  font-family: "poppins";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
}

.placement {
  font-family: "poppins";
  font-weight: normal;
  font-size: 20px;
}

.shortfeedback {
  margin-top: 10px;
  font-family: "poppins";
  font-size: 16px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  font-weight: 700px;
  max-width: 100%;
}

/* Swiper Pagination */
.swiper-pagination-bullet {
  background: #053859;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  background: #4facfe;
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card3 {
    width: 90%; /* Full width for smaller devices */
    height: 400px; /* Adjust height to content */
    margin-bottom: 30px;
    margin-left: 0px;
  }

  .linkedin-logo img {
    width: 40px;
    height: 40px;
  }

  .placement {
    font-size: 18px;
  }

  .shortfeedback {
    font-size: 14px;
    line-height: 1.2;
    max-width: 90%;
    font-weight: 900;
  }

  .training-feedback {
    font-size: 14px;
  }
}
