.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 91px;
    width: 100vw !important;
    padding: 0px 85px;
    background-color: white;
    margin-bottom: 20px;
    .logo{
        img{
            width: 100px;
            padding: 10px ;
            height: 100px !important;
        }
    }
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        &>*{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins', sans-serif;
            list-style: none;
            font-size: clamp(15px,1vw,2vw);
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 40px;
            color: #707070;
            opacity: 0.7;
            height: 100%;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                opacity: 1;
            }
        }
        
    }
    .mob{
        display: none;
    }
}

.focus{
    opacity: 1 !important;
    color: #053859 !important;
    font-weight: 900 !important;
    border-bottom: 5px solid #053859;
}

@media screen and (max-width:1200px){
    .Header{
        padding: 20px !important;
        ul{
            display: none;
        }
     .mob{
        display: block;
        .MobNav{
            position: absolute;
            z-index: 1;
            left: 38vw;
            border-radius: 12px;
            margin-top: 20px;
            width: 60vw;
            height: 300px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px #053859;
            background-color: white;
            ul{
                display: flex;
                align-items: center;
                padding: 0px;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                width: 100%;
                margin-top: 0px;
                &>*{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Poppins', sans-serif;
                    list-style: none;
                    font-size:20px;
                    font-weight: 500;
                    line-height: 0px;
                    width: 100%;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #707070;
                    margin-left: 0px;
                    opacity: 0.7;
                    height: 100%;
                    cursor: pointer;
                    text-decoration: none;
                    &:hover{
                        opacity: 1;
                    }
                }
                
            }
        }
     }
    }
}

.mobFocus{
    background-color: #053859 !important;
    color: white !important;
}